.About_heading .heading h1{
    width: 500px;
}
.About_heading .heading sub{
    top: -2.5rem !important
}
.employee_img{
width: 100%;
height: 250px;
object-fit: cover;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}



/* .About_heading .heading ::before {
    position: absolute;
    background-image: url('../../Assets/Icons/qoute.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
    top: 0;
    content:"";
} */

.Kaka_Div{
    height: 356px;
    width: 245px;
    background-color: rgb(138, 8, 30);
    border-radius: 8px;
}

.kaka_Image{
height: 356px;
left: 0;
object-fit: cover;
top: 0;
width: 245px;
padding: 5px;
background-color: rgb(203, 21, 51),
}