.studyWithUsStyles {
    background: linear-gradient(90deg, rgba(37,80,182, 1) 27%, rgba(62,156,228, 1) 85%);
    display: flex;
    position: relative;
    bottom: 10px;
    padding: 20px; /* Add padding for better spacing on small screens */
}

.studyTitle {
    color: rgba(255, 255, 255, 1);
}

.studyDescription {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    text-align: justify;
    float: left;
    position: relative;
    max-width: 100%;
}

.studyButton {
    border: none;
    background: none;
    color: rgba(255, 255, 255, 1) !important;
    font-weight: 600;
}

.studyButton:hover {
    color: rgb(240, 144, 18) !important;
    font-weight: 600;
}
@media (max-width: 767px) {
    .studyWithUsStyles {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    .studyDescription {
        float: none;
        text-align: center;
        height: auto !important;
        overflow: visible !important;
    }
    
      .studyButton {
        display: none;
      }
}


