@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.Hbox {
    height: 124px;
    width: 100%;
    z-index: 1000;
}

.Hbox .header {
    background-color: transparent;
    height: 124px;
    left: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
}

.custom-cursor-pointer{
    cursor: pointer;
}

.Hbox .overlap-group {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px #00000040;
    height: 124px;
    position: relative;
    width: 100%;
}

.Hbox .element-dbraei-logo {
    height: 100px;
    left: 119px;
    object-fit: cover;
    position: absolute;
    top: 10px;
    width: 70px;
}

.Hbox .text-wrapper {
    color: #333333;
    font-family: "Montserrat-Medium";
    font-size: 14px;
    font-weight: 500;
    left: 1011px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    top: 16px;
}

.Hbox .css-hz1bth-MuiDialog-container {
    position: relative !important;
    top: 125px !important;
    width: 90% !important;
    height: 80vh !important;
}

.Hbox .css-hz1bth-MuiDialog-container .css-m9glnp-MuiPaper-root-MuiDialog-paper {
    background-color: #192F59 !important;
    color: #ffffff !important;
    width: 100% !important;
    height: 100% !important;
}

.cart_nav {
    position: relative;
    border-right: 1px solid #BEC9C5;
}

.selected-item {
    color: #333333; /* Default color */
}
  
.selected-item.selected {
    color: white; /* Color when selected */
}

.selected-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgb(240, 90, 44) !important;
    transition: width 0.3s ease-in;
}
  
.selected-item:hover::after {
    width: 100%;
}

.cart_nav:nth-child(3n) {
    border: none;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    top: 110px !important;
    transform-origin: 147px 1px !important;
    opacity: 1;
    transform: none;
    transition: opacity 292ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 194ms cubic-bezier(0.4, 0, 0.2, 4) 0ms;
    position: relative;
}

.image-size {
    width: 260px;
    height: 5rem;
}

.justify-content-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

@media screen and (max-width: 2668px) {
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        margin: 3px 0px;
    }
}

#Header .col-sm-2 {
    display: flex;
    flex-direction: row;
    max-height: 135px;
}

#Header .col-sm-1 {
    display: flex;
    flex-direction: column;
}

/* Medium Devices, Desktops (992px and up) */
@media screen and (max-width: 992px) {
    .Hbox .element-dbraei-logo {
        height: 60px;
        left: 70px;
        top: 10px;
        width: 200px;
    }

    .Hbox .text-wrapper {
        font-size: 12px;
        left: auto;
        right: 10px;
        top: 10px;
    }
}

/* Small Devices, Tablets (768px and up) */
@media screen and (max-width: 768px) {
    .Hbox .element-dbraei-logo {
        height: 50px;
        left: 20px;
        top: 10px;
        width: 150px;
    }

    .Hbox .text-wrapper {
        font-size: 10px;
        left: auto;
        right: 10px;
        top: 5px;
    }
}

/* Extra Small Devices, Phones (576px and up) */
@media screen and (max-width: 576px) {
    .Hbox .element-dbraei-logo {
        height: 40px;
        left: 10px;
        top: 5px;
        width: 100px;
    }

    .Hbox .text-wrapper {
        font-size: 8px;
        left: auto;
        right: 5px;
        top: 2px;
    }
}
