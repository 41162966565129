/* Drawer Styles */
.drawer-container {
    text-align: center;
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drawer-button {
    font-weight: bold;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
}

.drawer-closeIcon {
    font-size: 24px;
}

.drawer-divider {
    background-color: var(--primary-contrast-text);
}

.drawer-list {
    flex-grow: 1;
}

.drawer-listItem {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.drawer-listItem:hover {
    color: #F15215;
}

.active-menu-item {
    background-color: #494798;
    color: white;
}

/* Mobile List Item */
.mobile-list-item {
    text-align: left;
    padding-left: 8px;
}

/* Submenu Styles */
.submenu-container {
    position: absolute;
    top: 100%;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    padding: 8px 0;
    min-width: 200px;
}

.submenu-item {
    position: relative;
}

.submenu-menuItem {
    display: flex;
    justify-content: space-between;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.submenu-menuItem:hover {
    background-color: #f2f2f2;
    cursor: pointer;
    transform: scale(1.05);
}

/* .nested_menu_container {
    position: absolute;
    left: calc(var(--depth, 0) * 100%);
    top: calc(var(--depth, 0) * 100%);
    background-color: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 1000;
    padding: 12px 0;
    min-width: 220px;
    width: auto;
    max-height: 400px;
    overflow-y: auto;
}

.nested_menu_dep_type {
    padding-left: 16px;
    padding-bottom: 4px;
    font-weight: 600;
    color: black;
    font-size: 1rem;
}

.nested_menu_divider {
    margin-bottom: 8px;
    border-color: #e0e0e0;
} */

.appbar_container {
    background-color: #DF5226;
    position: relative;
    max-height: 48px;
}

.box_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-left: 8rem;
}

