.course_offering_heading {
    background: linear-gradient(90deg, rgba(37, 80, 182, 1) 27%, rgba(62, 156, 228, 1) 95%);
    color: #fff;
    width: 250px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    /* Add Flexbox properties */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.course_offering_list{
    list-style-type: none;
    padding: 0;
}
.course_program_heading{
    background: linear-gradient(90deg, rgba(37, 80, 182, 1) 27%, rgba(62, 156, 228, 1) 95%);
    color: #fff;
}
.course_offering_list li {
    background-color: #d9d9d9;
    font-size: 20px;
    font-weight: 600;
}