/* Socialbar.css */

.social-bar {
  position: fixed;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(37,80,182, 0.9) 27%,rgba(62,156,228, 1)85%);
  padding: 10px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
  max-width: 4%;
  max-height: 100rem;
  transition: width 0.3s ease;
}

.social-bar a {
  color: white;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.social-bar i {
  font-size: 20px;
  margin: 5px;
}

/* Media Query for Small Screens */
@media screen and (max-width: 688px) {
  .social-bar {
    max-width: 6vw;
    max-height: none;
    top: auto;
    bottom: 0;
    border-radius: 10px 0 0 10px;
  }

  .social-bar a {
    width: 100%;
    min-height: auto;
  }
}
@media screen and (max-width: 488px) {
  .social-bar {
    display:none;
  }
}
