.course_infocard{
    padding: 20px 10px 20px 0;
    /* border-bottom: 1px solid #B1B1B1; */
    line-height: 20px;
    position: relative;
}
.course_infocard::before{
    content:"";
    position: absolute;
    bottom: 0;
    right:0px;
    left: 30px;
    height: 1px;
    width: 90%;
    background: #BEC9C5;
}
.Course_card_left{
    background-color: #192F59;
}
.Course_info_card_content{
    position: relative;   
}
.Course_info_card_content::before {
    content:"";
    position: absolute;
    top:0px;
    right:0px;
    height: 30px;
    width: 1px;
    background: #BEC9C5;
}
.tab_header_center .css-jpln7h-MuiTabs-scroller{
    display: flex !important;
    justify-content: center !important;
}