.wrapper {
  display: flex;
  justify-content: space-between;
}

.list-container {
  width: 20%;
}
.list {
  list-style-type: none;
  padding: 0;
}

.list-table {
  width: 100%;
  border-collapse:collapse;
}

.list-table th,
.list-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.Progress_Style{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-table th {
  background-color: #f2f2f2;
}

.document-viewer {
  flex: 3;
  margin-left: 20px;
  /* width: 100%; */
  height: 500px;
  border: 1px solid #ccc;
}
